import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "react-i18next";
import mobileSmartDivider from "../../images/locks/mobile-divider.svg";
import desktopSmartDivider from "../../images/locks/desktop-divider.svg";
import electronicDivider from "../../images/locks/electronic-divider.svg";
import {Link} from "gatsby-plugin-react-i18next";

const LocksProducts = () => {
  return (
      <>
        <section className="smart-locks">
          <div className="container">
            <h2>
              <Trans>
                digilock_purpose
              </Trans>
            </h2>
            <h2 className="sub">
              <Link to="/products/smart-locks/">
                <Trans>
                  smart_locks
                </Trans>
              </Link>
            </h2>
            <p>
              <Trans>
                smart_locks_copy
              </Trans>
            </p>
            <div className="smart-locks-container">
              <h2 class="sub legacy"><Trans>wirefree</Trans></h2>
              <div className="smart-lock-wrap">
                <Link to={'/products/smart-locks/curve/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-curve-smart-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Curve Smart Lock"
                    />
                    <p>
                      <span>Curve<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
                <Link to={'/products/smart-locks/aspire/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-net-aspire-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Aspire Smart Lock"
                    />
                    <p>
                      <span>Aspire<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
                <Link to={'/products/smart-locks/versa/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-net-versa-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Versa Smart Lock"
                    />
                    <p>
                      <span>Versa<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
                <Link to={'/products/smart-locks/orbit/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-net-orbit-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Orbit Smart Lock"
                    />
                    <p>
                      <span>Orbit<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
                <div className="digilink-lock">
                  <img src={desktopSmartDivider} width={'107'} height={'460'} alt=""/>
                  <Link to={'/products/smart-locks/#gotoDigilink'}>
                    <div className="thumbnail">
                      <StaticImage
                          src={'../../images/locks/DL22-digilink-600x497.png'}
                          loading={'lazy'}
                          width={200}
                          height={148}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="DigiLink Smart Lock"
                      />
                      <p>
                        <span>DigiLink<sup>&reg;</sup><br/>(<Trans>networked_management</Trans>)</span>
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <h2 class="sub legacy" style={{marginBottom: '1rem', marginTop: '1rem'}}><Trans>hardwired</Trans></h2>
              <div className="smart-lock-wrap">
                <Link to={'/products/smart-locks/pivot/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/pivot-system.png'}
                        loading={'lazy'}
                        width={221}
                        height={164}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Pivot with Kiosk"
                    />
                    <p>
                      <span>Pivot<sup>&trade;</sup></span><br/>
                    </p>
                  </div>
                </Link>
              </div>
              <img src={mobileSmartDivider} className={'mobile-smart-divider'} alt=""/>
            </div>

            <div className="smart-digilink-container">
              <div className="thumbnail">
                <StaticImage
                    src={'../../images/locks/DL22-digilink-600x497.png'}
                    loading={'lazy'}
                    width={200}
                    height={148}
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    alt="DigiLink Smart Lock"
                />
                <p>
                  <span>DigiLink<sup>&reg;</sup><br/><Trans>networked_management</Trans></span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="electronic-locks">
          <div className="container">
            <h2 className="sub">
              <Link to="/products/electronic-locks/">
                <Trans>
                  electronic_locks
                </Trans>
              </Link>
            </h2>
            <p>
              <Trans>
                electronic_locks_copy
              </Trans>
            </p>
            <div className="smart-locks-container">
              <h2 className="sub legacy"><Trans>wirefree</Trans></h2>
              <div className="smart-lock-wrap">
                <Link to={'/products/smart-locks/curve/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-curve-ba-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Curve Electronic Lock"
                    />
                    <p>
                      <span>Curve<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
                <Link to={'/products/smart-locks/aspire/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-ba-aspire-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Aspire Electronic Lock"
                    />
                    <p>
                      <span>Aspire<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
                <Link to={'/products/smart-locks/versa/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-ba-versa-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Versa Electronic Lock"
                    />
                    <p>
                      <span>Versa<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
                <Link to={'/products/smart-locks/orbit/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-net-orbit-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Orbit Smart Lock"
                    />
                    <p>
                      <span>Orbit<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
                <div className="management-lock">
                  <img src={mobileSmartDivider} className={'mobile-smart-divider'} alt=""/>
                  <img src={electronicDivider} className={'electronic-divider'} width={'107'} height={'250'} alt=""/>
                  <div>
                    <div className="thumbnail">
                      <StaticImage
                          src={'../../images/locks/electronic-keys.png'}
                          loading={'lazy'}
                          width={62}
                          height={100}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="DigiLink programming key"
                      />
                      <p>
                        <span><Trans>electronic_key</Trans><br/>(<Trans>basic_management</Trans>)</span>
                      </p>
                    </div>
                    <div className="thumbnail">
                      <StaticImage
                          src={'../../images/locks/mobile-tablet-management.png'}
                          loading={'lazy'}
                          width={121}
                          height={113}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="DigiLink Smart Lock"
                      />
                      <p>
                        <span><Trans>mobile_tablet</Trans><br/>(<Trans>advanced_management</Trans>)</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mechanical-locks">
          <div className="container mech">
            <h2 className="sub">

              <Link to={'/products/mechanical-locks/'}>
                <Trans>
                  mechanical_lock
                </Trans>
              </Link>

            </h2>

            <p><Trans>mechanical_lock_copy</Trans></p>

            <div className="row mech">
              <Link to={'/products/mechanical-locks/cleo/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/thumb-cleo.png'}
                      loading={'lazy'}
                      width={186}
                      height={119}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Cleo"
                  />
                  <p>
                    <span>Cleo<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
              <Link to={'/products/mechanical-locks/mech/'}>
                <div className="thumbnail">
                  <StaticImage
                      src={'../../images/locks/thumb-mech.png'}
                      loading={'lazy'}
                      width={186}
                      height={119}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Mech"
                  />
                  <p>
                    <span>Mech<sup>&trade;</sup></span>
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </>
  )
};
export default LocksProducts;
